<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <div class="d-flex flex-row flex-wrap align-items-center">
          <div class="card-label font-weight-bolder mr-6 text-success mb-3">View Leads By</div>
          <div class="flex-grow-0 filter-option mb-1">
            <b-form-select v-model="tempFilter" value="All" :options="tagFilters" />
          </div>
          <div class="flex-grow-0 filter-option pl-3 mb-3">
            <custom-datepicker v-model="tempStartDate" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
          </div>
          <div class="flex-grow-0 filter-option pl-3 mb-3">
            <custom-datepicker v-model="tempEndDate" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
          </div>
          <div class="flex-grow-0 filter-option pl-3 mb-3">
            <b-button class="btn" v-on:click="refreshList">Refresh List</b-button>
          </div>
        </div>
      </h3>
      <div class="card-toolbar">
        
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 150px" class="pl-7">
                  <span class="text-dark-75">Client Name</span>
                </th>
                <th style="min-width: 120px">Postcode</th>
                <th style="min-width: 100px">Phone Number</th>
                <th style="min-width: 100px">Date</th>
                <th style="min-width: 100px">Quote Recap</th>
                <th style="min-width: 101px"></th>
                <th style="min-width: 101px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(quote, i) in quotes">
                <tr v-bind:key="quote._id">
                  <td class="pl-1 pt-8">
                    <div class="d-flex align-items-center">
                      <div>
                        <a
                          href="#"
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >{{ quote.primaryMember.firstName || "Unknown" }} {{ quote.primaryMember.lastName || "Unknown" }}</a
                        >
                        <span class="text-muted font-weight-bold d-block">{{ quote.primaryMember.email || "No email" }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 d-block font-size-lg"
                      >{{ quote.primaryMember.postcode || "No postcode" }}</span
                    >
                    <span class="text-muted font-weight-bold"></span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 d-block font-size-lg"
                      >{{ quote.primaryMember.telephoneNumber || "No phone number" }}</span
                    >
                    <span class="text-muted font-weight-bold"></span>
                  </td>
                  <td>
                    <span
                      class="text-muted d-block font-size-lg"
                      >{{ quote.dateQuoted }}</span
                    >
                    <span class="text-muted font-weight-bold"></span>
                  </td>
                  <td>
                    <span class="btn btn-sm btn-light-success mb-1">
                      {{ quote.coverType }} 
                      <span v-if="quote.insurerId">
                        - {{ idToInsurer(quote.insurerId) }}
                      </span>  
                    </span>
                    <div>
                      <template v-for="(recap, index) in generateRecap(quote)">
                        <b-badge class="mr-1" v-bind:key="index" variant="success">{{ recap }}</b-badge>
                      </template>
                    </div>
                  </td>
                  <td class="pr-0 text-right">
                    <b-button
                      :id="`lead-popover-${i}`"
                      @click="logQuote(quote)"
                      class="btn btn-success font-weight-bolder font-size-sm"
                      >View Lead</b-button>
                    <b-popover
                      :target="`lead-popover-${i}`"
                      triggers="focus"
                      :content="`Placement ${i}`"
                      custom-class="lead-popover"
                    >
                      <template #title>
                        <b-row class="m-0">
                          <b-col>{{quote.primaryMember.firstName || "Unknown"}} {{ quote.primaryMember.lastName || "Unknown"}}</b-col>
                          <b-col>Quote Summary</b-col>
                          <b-col v-if="quote.application">Application Details</b-col>
                        </b-row>
                      </template>
                      <div>
                        <b-row class="mb-3">
                          <b-col>
                            <div>
                              <template v-for="(c,i) in clientDetailsKeys">
                              <b-row v-bind:key="i">
                                <b-col class="text-muted" cols="5">{{c.label}}:</b-col>
                                <b-col class="font-weight-bold text-capitalize">{{ quote.primaryMember[c.key] || "Unknown" }}</b-col>
                              </b-row>
                              </template>
                              <b-row>
                                <b-col class="text-muted" cols="5">Cover Type:</b-col>
                                <b-col class="font-weight-bold text-capitalize">{{ quote.coverType }}</b-col>
                              </b-row>
                              <b-row v-if="quote.hasExistingPolicy">
                                <b-col class="text-muted" cols="5">Years Insured:</b-col>
                                <b-col class="font-weight-bold">{{ yearsInsured(quote.primaryMember.yearsInsured) }}</b-col>
                              </b-row>
                              <b-row v-if="quote.hasExistingPolicy">
                                <b-col class="text-muted" cols="5">Years Claim Free:</b-col>
                                <b-col class="font-weight-bold">{{ yearsClaimFree(quote.primaryMember.yearsClaimFree) }}</b-col>
                              </b-row>
                              <b-row v-if="quote.hasExistingPolicy">
                                <b-col class="text-muted" cols="5">Number of Claims:</b-col>
                                <b-col class="font-weight-bold">{{ numberOfClaims(quote.primaryMember.numberOfClaims) }}</b-col>
                              </b-row>
                            </div>
                          </b-col>
                          <b-col>
                            <div>
                              <template v-for="(c,i) in coverDetailsKeys">
                              <b-row v-bind:key="`cover-details-${i}`">
                                <b-col class="text-muted" cols="7">{{c.label}}</b-col>
                                <b-col class="font-weight-bold text-capitalize">{{ quote[c.key] }}</b-col>
                              </b-row>
                              </template>
                              <template v-for="(c,i) in coverDetailsBooleanKeys">
                              <b-row v-bind:key="`cover-details-bool-${i}`">
                                <b-col class="text-muted" cols="7">{{c.label}}</b-col>
                                <b-col class="font-weight-bold text-capitalize">{{ yesNo(quote[c.key]) }}</b-col>
                              </b-row>
                              </template>
                            </div>
                          </b-col>
                          <b-col v-if="quote.application">
                            <b-row class="mb-2">
                              <b-col class="font-weight-bolder text-capitalize">Bank Details</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Account Name</b-col>
                              <b-col class="font-weight-bold text-capitalize">{{ quote.application.accountName }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Account Number</b-col>
                              <b-col class="font-weight-bold text-capitalize">{{ quote.application.accountNumber }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Sort Code</b-col>
                              <b-col class="font-weight-bold text-capitalize">{{ quote.application.sortCode }}</b-col>
                            </b-row>
                            <b-row class="mb-2 mt-4">
                              <b-col class="font-weight-bolder text-capitalize">Product Selected</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Insurer</b-col>
                              <b-col class="font-weight-bold text-capitalize">{{ quote.application.productRefId.companyName }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Product</b-col>
                              <b-col class="font-weight-bold text-capitalize">{{ quote.application.productRefId.product }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Selected Price</b-col>
                              <b-col class="font-weight-bold text-capitalize">£{{ quote.application.selectedPrice }}</b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-muted" cols="6">Selected Excess</b-col>
                              <b-col class="font-weight-bold text-capitalize">£{{ quote.application.selectedExcess }}</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <div class="mt-5 mb-5" v-if="quote.dependentMembers.length > 0">
                            <b-row>
                              <b-col class="text-muted" cols="5">Dependents:
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="font-weight-bold">Name</b-col>
                              <b-col class="font-weight-bold">DOB</b-col>
                              <b-col class="font-weight-bold">Relationship</b-col>
                              <b-col class="font-weight-bold" v-if="quote.hasExistingPolicy">Years Insured</b-col>
                              <b-col class="font-weight-bold" v-if="quote.hasExistingPolicy">Years Claim Free</b-col>
                              <b-col class="font-weight-bold" v-if="quote.hasExistingPolicy">Number of Claims</b-col>
                            </b-row>
                            <template v-for="(d, key) in quote.dependentMembers">
                            <b-row v-bind:key="key">
                              <b-col class="">{{d.firstName}} {{d.lastName}}</b-col>
                              <b-col class="">{{toUKDate(d.dateOfBirth)}}</b-col>
                              <b-col class="text-capitalize">{{d.relationship}}</b-col>
                              <b-col class="" v-if="quote.hasExistingPolicy">{{yearsInsured(d.yearsInsured)}}</b-col>
                              <b-col class="" v-if="quote.hasExistingPolicy">{{yearsClaimFree(d.yearsClaimFree)}}</b-col>
                              <b-col class="" v-if="quote.hasExistingPolicy">{{numberOfClaims(d.numberOfClaims)}}</b-col>
                            </b-row>
                            </template>
                            
                          </div>
                        <b-row align-v="center">
                          <b-col></b-col>
                          <b-col>
                            <b-form-group class="m-0" v-slot="{ ariaDescribedby }">
                              <b-form-select
                                v-model="quote.tag"
                                :options="tagOptions"
                                :aria-describedby="ariaDescribedby"
                                buttons
                                v-on:change="updateQuote(quote._id, {tag : quote.tag})"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-popover>
                  </td>
                  <td>
                    <a
                      href="#"
                      class="btn btn-danger font-weight-bolder font-size-sm"
                      v-bind:class="leadClass(quote.tag)"
                      >{{ quote.tag }}</a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div class="overflow-auto">
          <b-pagination 
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <!-- Modals -->
        <div class="modals">
          <b-modal ref="sales-modal" hide-footer title="Converting to sales">
            <div class="d-block sales-modal">
              <b-form-group class="strong" label="Insurer" v-slot="{ ariaDescribedby }">
                <b-form-select
                  v-model="insurerId"
                  :options="insurers"
                  :aria-describedby="ariaDescribedby"
                  name="insurer"
                  buttons
                ></b-form-select>
              </b-form-group>
              <b-form-group class="strong" label="Plan Name" v-slot="{ ariaDescribedby }">
                <b-form-input
                  v-model="planName"
                  :aria-describedby="ariaDescribedby"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group class="strong" label="Premium" v-slot="{ ariaDescribedby }">
                <b-form-input
                  v-model="premium"
                  :aria-describedby="ariaDescribedby"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group class="strong" label="Payment" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="paymentMode"
                  class="rq-toggle"
                  :options="paymentOptions"
                  :aria-describedby="ariaDescribedby"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group class="strong" label="Cover start date" v-slot="{ ariaDescribedby }">
                <b-form-datepicker 
                  id="cover-start" 
                  v-model="coverStartDate" 
                  class="mb-2"
                  :aria-describedby="ariaDescribedby"
                  show-decade-nav
                  locale="en-gb"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <b-row>
              <b-col>
                <b-button class="mt-3" variant="success" block @click="hideModal">Save</b-button>    
              </b-col>
              <b-col>
                <b-button class="mt-3" variant="danger" block @click="hideModal">Cancel</b-button>
              </b-col>
            </b-row>
          </b-modal>
        </div>
        <!-- End modals -->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import { mapGetters } from "vuex";
import CustomDatepicker from "@/component/CustomDatepicker.vue";
const moment = require('moment');

export default {
  name: "leads-widget",
  components: {
    CustomDatepicker
  },
  data () {
    return {
      tempFilter: "All",
      tempStartDate: "",
      tempEndDate: "",
      activeFilter: "All",
      startDateFilter: "",
      endDateFilter: "",
      isLoading: false,
      clientDetailsKeys: [
        { key: "title", label: "Title" },
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "gender", label: "Gender" },
        { key: "telephoneNumber", label: "Telephone No." },
        { key: "landlineNumber", label: "Landline No." },
        { key: "email", label: "Email" },
        { key: "dateOfBirth", label: "Date of Birth" },
        { key: "isSmoker", label: "Smoker" },
        { key: "postcode", label: "Postcode" },
        { key: "address1", label: "Address 1" },
        { key: "address2", label: "Address 2" },
        { key: "town", label: "Town" },
        { key: "county", label: "County" },
      ],
      coverDetailsKeys: [
        { key: "underwriting", label: "Underwriting" },
        { key: "coverage", label: "Coverage" },
        { key: "hospital", label: "Hospital" },
        { key: "gpRegistered", label: "GP Registered" },
        { key: "excessLevel", label: "Preferred Excess Level" }
      ],
      coverDetailsBooleanKeys: [
        { key: "dental", label: "Dental" },
        { key: "treatment", label: "Therapies" },
        { key: "psychiatric", label: "Mental Health" },
        { key: "sixWeek", label: "Six Week" },
        { key: "guidedOption", label: "Guided Option" },
        { key: "hasExistingPolicy", label: "Existing Policy" },
        { key: "hasDiagnosedIllness", label: "Diagnosed Illness" },
        { key: "hasPreviousCheckup", label: "Previous Checkup" },
        { key: "hasTreatment", label: "Previous Treatment" },
      ],
      tagOptions: [
        { value: "New Lead", text: "New Lead" },
        { value: "No Reply", text: "No Reply" },
        { value: "Invalid No.", text: "Invalid No." },
        { value: "Contacted", text: "Contacted" },
        { value: "Quoted", text: "Quoted" },
        { value: "Emailed", text: "Emailed" },
        { value: "Not Interested", text: "Not Interested" },
        { value: "Sold", text: "Sold" },
        { value: "Sold - On Risk", text: "Sold - On Risk" },
        { value: "Commission Paid", text: "Commission Paid" },
        { value: "In Underwriting", text: "In Underwriting" },
        { value: "Not Taken Up", text: "Not Taken Up" },
        { value: "Awaiting Cert", text: "Awaiting Cert" }
      ],
      paymentOptions: [
        { value: "monthly", text: "Monthly" },
        { value: "annual", text: "Annual" }
      ]
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated"
    ]),
    tagFilters: {
      get() {
        var options = this.tagOptions;
        options.unshift({ value: "All", text: "All" });

        return options;
      }
    },
    quotes: {
      get() {
        return this.$store.state.leads.quotes.filter(item => item.primaryMember != undefined).map(item => {
          item.primaryMember.dateOfBirth = moment(item.primaryMember.dateOfBirth).format('DD/MM/YYYY');
          item.primaryMember.isSmoker = item.primaryMember.isSmoker ? "Yes" : "No";
          item.dateQuoted = moment(item.dateQuoted).format("DD/MM/YYYY h:mm:ss");

          return item;
        });
      }
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        var params = this.pageParams;
        params["page"] = page;
        this.$store.dispatch("getQuotes", params);
      }
    },
    totalRows: {
      get() {
        return this.pagination.totalDocs;
      }
    },
    perPage: {
      get() {
        return this.pagination.limit;
      }
    },
    pagination: {
      get() {
        return this.$store.state.leads.pagination;
      }
    },
    insurers: {
      get() {
        return this.$store.state.leads.insurers.map(insurer => ({value: insurer._id, text: insurer.insurerName}));
      }
    },
    insurerId: {
      get() {
        return this.$store.state.leads.sale.insurerId;
      },
      set(value) {
        let sale = { ...this.sale };
        sale.insurerId = value;
        this.$store.commit("updateSale", sale);
      }
    },
    planName: {
      get() {
        return this.$store.state.leads.sale.planName;
      },
      set(value) {
        let sale = { ...this.sale };
        sale.planName = value;
        this.$store.commit("updateSale", sale);
      }
    },
    pageParams: {
      get() {
        return {
          page: this.currentPage,
          leadType: this.activeFilter,
          startDate: this.startDateFilter,
          endDate: this.endDateFilter
        }
      }
    },
    premium: {
      get() {
        return this.$store.state.leads.sale.premium;
      },
      set(value) {
        let sale = { ...this.sale };
        sale.premium = value;
        this.$store.commit("updateSale", sale);
      }
    },
    paymentMode: {
      get() {
        return this.$store.state.leads.sale.paymentMode;
      },
      set(value) {
        let sale = { ...this.sale };
        sale.paymentMode = value;
        this.$store.commit("updateSale", sale);
      }
    },
    coverStartDate: {
      get() {
        return this.$store.state.leads.sale.coverStartDate;
      },
      set(value) {
        let sale = { ...this.sale };
        sale.coverStartDate = value;
        this.$store.commit("updateSale", sale);
      }
    },
    sale: {
      get() {
        return this.$store.state.leads.sale;
      }
    }
  },
  methods: {
    toUKDate: function(date){
      return moment(date).format("DD/MM/YYYY");
    },
    yearsInsured: function(id){
      switch(id){
        case 2: return "1 Year";
        case 3: return "2-3 Years";
        case 4: return "3-4 Years";
        case 5: return "4-5 Years";
        case 6: return "5 years or more"
      }
    },
    yearsClaimFree: function(id){
      switch(id){
        case 0: return "Less than a year";
        case 1: return "1 Year";
        case 2: return "2-3 Years";
        case 3: return "3-4 Years";
        case 4: return "4-5 Years";
        case 5: return "5 years or more"
      }
    },
    numberOfClaims: function(id){
      switch(id){
        case "0": return "0";
        case "1": return "1";
        case "2": return "2";
        case "3": return "3+";
      }
    },
    idToInsurer: function(insurerId){
      switch(insurerId){
        case 3: return "CS Healthcare";
        case 5: return "WPA";
        case 6: return "AXA";
        case 7: return "Aviva";
        case 8: return "Freedom";
        case 9: return "Vitality";
        case 16: return "Vitality (Switch & Save)";
        case 12: return "Westfield Health";
        case 18: return "Exeter";
        case 21: return "General and Medical";
        case 22: return "BUPA";
        case 23: return "Morgan Price";
      }
    },
    showModal: function(value){
      if(value){
        this.$refs['sales-modal'].show();
      }
    },
    hideModal: function(){
      this.$refs['sales-modal'].hide();
    },
    logQuote: function(quote){
      console.log(quote);
    },
    yesNo: function(value){
      return value ? "Yes" : "No";
    },
    generateRecap: function(coverDetails){
      let recapData = [];
      
      switch(coverDetails.underwriting){
        case "NMORI":
          recapData.push("Mori");
          break;
        case "CPME":
          recapData.push("CPME");
          break;
        case "CMORI":
          recapData.push("CMori");
          break;
        case "FMU":
          recapData.push("FMU");
          break;  
      }

      switch(coverDetails.coverage){
        case "basic":
          recapData.push("No OP");
          break;
        case "intermediate":
          recapData.push("Limited OP");
          break;
        case "full":
          recapData.push("Full OP");
          break;
      }

      return recapData;
    },
    updateQuote: function(quoteId, quote){
      this.$store.dispatch("updateQuote", {
        quoteId: quoteId,
        quote: quote
      })
      .then(() => {

      })
      .catch(() => {

      });
    },
    leadClass: function(leadTag){
      return {
        'tag-new-lead' : leadTag == "New Lead",
        'tag-no-reply' : leadTag == "No Reply",
        'tag-invalid-no' : leadTag == "Invalid No.",
        'tag-contacted' : leadTag == "Contacted",
        'tag-quoted' : leadTag == "Quoted",
        'tag-emailed' : leadTag == "Emailed",
        'tag-interested' : leadTag == "Not Interested",
        'tag-sold' : leadTag == "Sold",
        'tag-sold-risk' : leadTag == "Sold - On Risk",
        'tag-commission-paid' : leadTag == "Commission Paid",
        'tag-in-underwriting' : leadTag == "In Underwriting",
        'tag-awaiting-cert' : leadTag == "Awaiting Cert",
        'tag-not-taken' : leadTag == "Not Taken Up",
      }
    },
    refreshList: function(){
      this.activeFilter = this.tempFilter;
      this.startDateFilter = this.tempStartDate;
      this.endDateFilter = this.tempEndDate;

      var params = this.pageParams;
      params["page"] = 1;
      
      this.$store.dispatch("getQuotes", this.pageParams).then(() => {})
      .catch(() => {});
    }
  },
  mounted () {
    // Initial load of quotes
    if (this.isAuthenticated) {
      this.$store.dispatch("getQuotes", this.pageParams).then(() => {})
      .catch(() => {});
    }
  }
};
</script>

<style>
.lead-popover {
  min-width: 950px;
}

.filter-option {
  width: 250px;
}
</style>